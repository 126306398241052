export const fontSizePxToRem = (px) => `${(px) / 16}rem`;
export const letterSpacingPxToEm = (px) => `${px / 15}em`;

const variantTypography = {
    fontFamily: [
        'Roboto'
    ].join(','),

    h1: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(24),
        fontWeight: 800,
        lineHeight: 1.5,
        letterSpacing: letterSpacingPxToEm(0.33),

    },
    h2: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(20),
        fontWeight: 800,
        lineHeight: 1.4,
        letterSpacing: letterSpacingPxToEm(0.23),
    },
    h3: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(16),
        fontWeight: 600,
        lineHeight: 1.33,
        letterSpacing: letterSpacingPxToEm(0.36),
    },
    h4: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(14),
        fontWeight: 600,
        lineHeight: 1.33,
        letterSpacing: letterSpacingPxToEm(0.34),
    },
    h5: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(13),
        fontWeight: 900,
        lineHeight: 1.33,
        letterSpacing: letterSpacingPxToEm(0.26),
    },
    h6: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(14),
        fontWeight: 600,
        lineHeight: 1.33,
        letterSpacing: letterSpacingPxToEm(0.12),
    },
    caption: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(14),
        fontWeight: 400,
        lineHeight: 1.33,
        letterSpacing: letterSpacingPxToEm(0.12),
    },
    subtitle1: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(14),
        fontWeight: 400,
        lineHeight: 1.33,
        letterSpacing: letterSpacingPxToEm(0.12),
    },
    subtitle2: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(14),
        fontWeight: 400,
        lineHeight: 1.33,
        letterSpacing: letterSpacingPxToEm(0.12),
    },
    body1: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(14),
        fontWeight: 600,
        lineHeight: 1.33,
        letterSpacing: letterSpacingPxToEm(0.12),
    },
    body2: {
        fontFamily: 'Roboto',
        fontSize: fontSizePxToRem(14),
        fontWeight: 400,
        lineHeight: 1.33,
        letterSpacing: letterSpacingPxToEm(0.12),
    },

};

export const appTypography = {
    ...variantTypography,
};
