import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.scss";
import { Box, IconButton, Modal } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { convertTimestamp } from "../../utils/utils";
import ViewIncident from "../ViewIncident/viewIncident";

function MediaCard({ incident, handleClick }) {
  return (
    <div className={styles.cardContainer}>
      <Card
        className={styles.card}
        key={incident.id}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(incident);
        }}
      >
        <CardMedia
          sx={{ height: 150, borderRadius: "10px" }}
          image={
            incident.url
              ? incident.url
              : incident.imageUrl
              ? incident.imageUrl
              : ""
          }
        />
        <CardContent sx={{ padding: "12px 0" }}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}
          >
            <Typography variant="h4">Adresse</Typography>
            <Typography variant="h5" sx={{ color: "#FF6A1C" }}>
              {incident.status}
            </Typography>
          </Box>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={{ paddingBottom: 0 }}
          >
            {incident.address}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

// onClick={(e) => { e.stopPropagation(); getMarkerDetails(incident.name); handleView('VIEW'); }
function CardsList({ incident, handleClick }) {
  return (
    <div className={styles.listContainer}>
      <Card
        className={styles.listCard}
        key={incident.id}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(incident);
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "80%" }}>
          <CardContent sx={{ flex: "1 0 auto", p: 0 }}>
            <Typography component="div" variant="h4">
              {incident.name}
            </Typography>
          </CardContent>
          <Box sx={{ display: "flex", alignItems: "start" }}>
            <Typography
              variant="h5"
              color="text.secondary"
              component="div"
              sx={{ fontSize: "12px" }}
            >
              Gemeldet am: &nbsp;
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{ fontSize: "12px" }}
            >
              {convertTimestamp(incident.timestamp, true)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "start" }}>
            <Typography
              variant="h5"
              color="text.secondary"
              component="div"
              sx={{ fontSize: "12px" }}
            >
              Kategorie: &nbsp;
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{ fontSize: "12px" }}
            >
              {incident.category}
            </Typography>
          </Box>
          {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ width: '280px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="subtitle1" color="text.secondary" component="div">
              {incident.description}
            </Typography>
          </Box> */}
        </Box>
        <CardMedia
          component="img"
          sx={{ width: 60, height: 60, borderRadius: "10px" }}
          image={
            incident?.url
              ? incident.url
              : incident?.images.length > 0
              ? URL.createObjectURL(incident.images[0])
              : ""
          }
          alt="No Image"
        />
      </Card>
    </div>
  );
}

const IncidentLists = ({ incidents }) => {
  const [viewStateCard, setViewStateCard] = useState(false);
  const [openIncident, setOpenIncident] = useState({});

  const handleOpenIncidentDetails = (incident) => {
    setOpenIncident(incident);
  };

  const handleIncidentClose = () => {
    setOpenIncident({});
  };
  return (
    <>
      {Object.keys(openIncident).length > 0 ? (
        <Modal open={Object.keys(openIncident).length > 0}>
          <div className={styles.viewContainer}>
            <ViewIncident
              handleClose={handleIncidentClose}
              incident={openIncident}
            />
          </div>
        </Modal>
      ) : (
        <div
          className={`${styles.wrapper} ${
            viewStateCard && styles.listViewWidth
          }`}
        >
          <div className={styles.listType}>
            {/* <Button
              variant="outlined"
              className={styles.toggleView}
              startIcon={<FormatListBulletedIcon />}
              onClick={() => setViewStateCard(!viewStateCard)}
            >
              {/* {viewStateCard ? "Thumb View" : "List View"} */}
            {/* </Button> */}
            {incidents.length >= 0 && (
              <IconButton
                variant="outlined"
                className={styles.toggleView}
                aria-label="view change"
                onClick={() => setViewStateCard(!viewStateCard)}
              >
                <FormatListBulletedIcon fontSize="large" />
              </IconButton>
            )}
          </div>
          <div
            className={`${
              viewStateCard ? styles.listViewWrapper : styles.cardViewWrapper
            }`}
          >
            <div className={styles.viewContent}>
              {!viewStateCard
                ? incidents.map((incident, index) => (
                    <MediaCard
                      incident={incident}
                      handleClick={handleOpenIncidentDetails}
                      key={`incident-media-card-${index}`}
                    />
                  ))
                : incidents.map((incident, index) => (
                    <CardsList
                      incident={incident}
                      handleClick={handleOpenIncidentDetails}
                      key={`incident-card-${index}`}
                    />
                  ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IncidentLists;
