import {
  appLightPalette,
  appLightColors,
  appCustomColors,
} from "./lightPalette";
import { appDarkPalette, appDarkColors } from "./darkPalette";
import { appTypography } from "./typography";
import { createTheme, responsiveFontSizes } from "@mui/material";
// Create a theme instance.
const theme = (mode = "light", color = "blue") =>
  responsiveFontSizes(
    createTheme({
      typography: appTypography,
      palette:
        mode === "light" ? { ...appLightPalette } : { ...appDarkPalette },
      mixins:
        mode === "light"
          ? { ...appLightColors, ...(appCustomColors[color] || {}) }
          : appDarkColors,
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            "@global": {
              ".thumbnail": {
                maxWidth: "5rem",
                borderRadius: "10px",
                margin: "0.6rem 0",
                width: "100%",
                height: "100%",
                maxHeight: "3.5rem",
              },
              "MuiIconButton-colorSecondary": {
                // color: mode === 'light' ? 'rgba(0, 0, 0, 0.54)': '#fff'
                color: "#fff",
              },
              "MuiTextField-root": {
                width: "100%",
              },
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              whiteSpace: "nowrap",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
    })
  );
export default theme;
