export const MAX_COUNT = 5;
export const GERMAN_LOCALE = "de-DE";
export const menuList = [
  "Abfall/Sammelstelle",
  "Beleuchtung/Uhren",
  "Brunnen/Hydranten",
  "Graffiti",
  "Grünflächen/Spielplätze",
  "Schädlinge",
  "Signalisation/Lichtsignal",
  "Strasse/Trottoir/Platz",
  "Tierkadaver",
];

export const convertTimestamp = (timestamp, hour) => {
  var dateString = new Date(timestamp);
  return hour
    ? `${dateString.toLocaleDateString(
        GERMAN_LOCALE
      )} ${dateString.toLocaleTimeString(GERMAN_LOCALE)}`
    : dateString.toDateString();
};
