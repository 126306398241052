import L from "leaflet";
import marker from "../../../assets/marker.png";
import greenMarker from "../../../assets/green_marker.png";
import yellowMarker from "../../../assets/yellow_marker.png";
import { IncidentStatus } from "../../../models/IncidentStatus";
import { Marker, useMap } from "react-leaflet";
import ViewIncident from "../../ViewIncident/viewIncident";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";

function IncidentMarkerPopup({
  cards,
  setIsMarkerPanMovement,
  setCurrentMarker,
}) {
  const mapRef = useMap();
  const getMarkerBasedOnIncidentStatus = (incident) => {
    let iconUrl = marker;
    const incidentStatus = (incident.status ?? "").trim();
    if (incidentStatus === IncidentStatus.IN_PROGRESS) {
      iconUrl = yellowMarker;
    } else if (
      incidentStatus === IncidentStatus.ANSWERED ||
      incidentStatus === IncidentStatus.COMPLETED
    ) {
      iconUrl = greenMarker;
    }
    return new L.icon({
      iconUrl,
      iconRetinaUrl: iconUrl,
      iconSize: [30, 30],
    });
  };
  //   () => ({
  //     click(e) {
  //       setIsMarkerPanMovement(true);
  //       setCurrentMarker(e.target);

  //       e.target.bindPopup(
  //         //get popup content as text
  //         () => {
  //           const div = document.createElement("div");
  //           const root = createRoot(div);
  //           flushSync(() => {
  //             root.render(
  //               <ViewIncident
  //                 incident={JSON.parse(e.target.options.markerInfo)}
  //                 showCloseButton={false}
  //               />
  //             );
  //           });
  //           return div.innerHTML;
  //         },
  //         {
  //           autoPan: false,
  //           autoPanOnFocus: false,
  //           className: "view-incident-popup-container",
  //         }
  //       );

  //       mapRef.panTo(e.latlng);
  //     },
  //   }),
  //   []
  // );

  const onMarkerClick = (e) => {
    setIsMarkerPanMovement(true);
    setCurrentMarker(e.target);

    e.target.bindPopup(
      //get popup content as text
      () => {
        const div = document.createElement("div");
        const root = createRoot(div);
        flushSync(() => {
          root.render(
            <ViewIncident
              incident={JSON.parse(e.target.options.markerInfo)}
              showCloseButton={false}
            />
          );
        });
        return div.innerHTML;
      },
      {
        autoPan: false,
        autoPanOnFocus: false,
        className: "view-incident-popup-container",
      }
    );

    mapRef.panTo(e.latlng);
  };

  return (
    <>
      {cards?.length > 0 &&
        cards.map((marker, index) => (
          <Marker
            key={marker.id}
            position={[marker.position[0], marker.position[1]]}
            icon={getMarkerBasedOnIncidentStatus(marker)}
            eventHandlers={{ click: onMarkerClick }}
            markerInfo={JSON.stringify(marker)}
          />
        ))}
    </>
  );
}

export default IncidentMarkerPopup;
