import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

import styles from "./style.module.scss";
import { useEffect, useState } from "react";
import { TextField, Snackbar, Alert, Button } from "@mui/material";
import { isLocationActive } from "../../services/client";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PlacesAutocomplete = ({ addNewMarker, markerData }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    cache: 86400,
    requestOptions: {
      componentRestrictions: {
        country: "ch",
      },
    },
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  const [valueTouch, setValueTouch] = useState(false);
  const [message, setMessage] = useState("");
  const [isErrorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [isCheckingCurrentLocation, setIsCheckingCurrentLocation] =
    useState(false);

  useEffect(() => {
    setErrorSnackbarOpen(false);
    setValue(markerData?.description ?? "", false);
    clearSuggestions();
    if (markerData) {
      setIsCheckingCurrentLocation(true);
      isLocationActive(markerData.lng, markerData.lat, markerData.description)
        .then((res) => {
          setMessage("");
          setErrorSnackbarOpen(false);
        })
        .catch((err) => {
          setMessage("Für diese Adresse kann keine Meldung erfasst werden.");
          setErrorSnackbarOpen(true);
        })
        .finally(() => setIsCheckingCurrentLocation(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerData]);

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setValueTouch(true);
  };

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarOpen(false);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        const address = results[0].formatted_address.replace(
          ", Switzerland",
          ""
        );
        addNewMarker({ lat, lng, description: address });
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      const modifiedSecondary = secondary_text?.replace(", Switzerland", "");

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{modifiedSecondary}</small>
        </li>
      );
    });

  return (
    <div className={styles.autoCompleteContainer} ref={ref}>
      <TextField
        onChange={handleInput}
        value={value}
        disabled={!ready}
        placeholder="Adresse suchen"
        error={valueTouch && !value ? true : false}
        className={styles.autoCompleteTextField}
        sx={{
          background: "white",
        }}
        inputProps={{ style: { fontSize: "16px" } }}
      />
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
      <p className="addressPinButton">
        **Oder klicken Sie auf die Karte, um stattdessen einen Standort
        anzupinnen
      </p>

      <Snackbar open={!!message && isErrorSnackbarOpen}>
        <Alert
          severity="error"
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={handleErrorSnackbarClose}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        >
          <span className="location-alert-message">{message}</span>
        </Alert>
      </Snackbar>
      <Snackbar open={isCheckingCurrentLocation}>
        <Alert severity="info">
          <span className="location-alert-message">
            Es wird geprüft, ob der ausgewählte Standort unterstützt wird...
          </span>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PlacesAutocomplete;
