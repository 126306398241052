import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from "@mui/icons-material/Circle";
import Link from "@mui/material/Link";
import styles from "./app-info-dialog.scss";
import F4dMeldeserviceIcon from "../../assets/f4d-meldeservice-icon.svg";

const AppCustomStyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const infoList = [
  "Ungefähre Adresse des Schadens",
  "Lokalisieren Sie den Schaden auf der Karte",
  "Beschreiben Sie den Schaden",
];

export default function AppInfoDialog({ isInfoDialogOpen, closeInfoDialog }) {
  return (
    <AppCustomStyledDialog
      aria-labelledby="customized-dialog-title"
      open={isInfoDialogOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <img
          src={F4dMeldeserviceIcon}
          height={50}
          width={50}
          alt="Fix My Street App Logo"
        ></img>
        Melden Sie Schäden und Mängel an der Infrastruktur des Kantons Aargau
      </DialogTitle>
      <DialogContent className="infoDialogContent" dividers>
        <Typography gutterBottom>Erfassen Sie eine neue Meldung:</Typography>
        <List className="infoList">
          {infoList.map((infoListItem, index) => (
            <ListItem className="infoListItem" key={"infoListItem" + index}>
              <ListItemIcon>
                <CircleIcon sx={{ fontSize: 10, color: "black" }} />
              </ListItemIcon>
              <ListItemText primary={infoListItem} />
            </ListItem>
          ))}
        </List>
        <Typography gutterBottom className="infoMessage">Für eine reibungslose Behebung Ihrer Meldung tragen Sie bitte Ihre Kontaktdaten für Rückfragen und Status-Meldungen ein.</Typography>
        <Typography gutterBottom>
          <Link
            href={process.env.REACT_APP_TERMS_AND_CONDITIONS}
            target="_blank"
            rel="noopener"
          >
            Rechtliche Hinweise - Kanton Aargau (ag.ch)
          </Link>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={closeInfoDialog}>
          Weiter
        </Button>
      </DialogActions>
    </AppCustomStyledDialog>
  );
}
