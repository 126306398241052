import { useMemo, useRef } from "react";
import newMarker from "../../../assets/new_marker.png";
import L from "leaflet";
import { Marker } from "react-leaflet";

const newCustomMarker = new L.icon({
  iconUrl: newMarker,
  iconRetinaUrl: newMarker,
  iconSize: [30, 30],
});

function LocationMarker({
  currentFunc,
  setCurrentFunc,
  newPos,
  setNewPos,
  calculateAddressAndUpdate,
}) {
  const markerRef = useRef(null);
  // eslint-disable-next-line no-unused-vars

  const markerEventHandlers = useMemo(
    () => ({
      dragend() {
        if (currentFunc === "CURRENT") {
          setCurrentFunc("ADD");
        }
        const marker = markerRef.current;
        if (marker !== null) {
          const pos = marker.getLatLng();
          setNewPos({
            latitude: pos.lat,
            longitude: pos.lng,
          });
          calculateAddressAndUpdate(pos.lat, pos.lng);
        }
      },
    }),
    []
  );

  return (
    !!newPos && (
      <Marker
        eventHandlers={markerEventHandlers}
        position={[newPos.latitude, newPos.longitude]}
        icon={newCustomMarker}
        ref={markerRef}
      />
    )
  );
}

export default LocationMarker;
