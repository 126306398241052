import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import styles from "./style.module.scss";


const steps = [
  { name: "Schritt - 1", description: "Ort wählen" },
  { name: "Schritt - 2", description: "Details" },
];

function FormStep({ currentStep }) {
  return (
    <div className={styles.stepContainer}>
      <Stepper activeStep={currentStep - 1} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: '#189f00', // circle color (COMPLETED)
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: '#fff', // circle color (ACTIVE)
              },
              '& .MuiStepLabel-root .Mui-disabled .MuiSvgIcon-root': {
                color: 'transparent', // circle color (ACTIVE)
                border: '1px solid #fff',
                borderRadius: '50%'
              },
              '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                color: '#fff', // text label
              },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: '#217ab8', // circle's number (ACTIVE)
              },
              '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text': {
                fill: '#fff', // circle's number (COMPLETED)
              },
              '& .MuiStepConnector-root .MuiStepConnector-line': {
                borderColor: '#fff', // connector line
                borderTopWidth: '2px'
              },
            }}
          >
            <StepLabel>
              <div>{step.name}</div>
              <div>{step.description}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default FormStep;
