import PlacesAutocomplete from "../autoComplete/autoComplete";
import NavContainer from "../nav/nav";
import styles from "./styles.module.scss";
import F4dMeldeserviceIcon from "../../assets/f4d-meldeservice-icon-white.svg";

function Header({ currentNav, moveTo, addNewMarker, markerData }) {
  return (
    <div
      className={`${styles.headerContainer} ${
        currentNav === "New" && styles.newStepHeader
      }`}
    >
      <div className={styles.appLogo}>
        <img
          alt="Meldeservice Logo"
          src={F4dMeldeserviceIcon}
          className={styles.appLogo}
        />
      </div>
      <div className={styles.navContainer}>
        <NavContainer currentPage={currentNav} moveTo={moveTo} />
      </div>
      <div className={styles.searchContainer}>
        <PlacesAutocomplete
          addNewMarker={addNewMarker}
          markerData={markerData}
        />
      </div>
    </div>
  );
}

export default Header;
