import React, { useEffect, useState } from "react";
import { MapContainer, ZoomControl, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "./mapViewer.scss";
import "leaflet.gridlayer.googlemutant";
import { getAddress, getIncidentByViewBox } from "../../services/client";
import { getCoOrds } from "./map-co-ords";
import MapEventHandler from "./mapEventHandler/mapEventHandler";
import LocationMarker from "./locationMarker/locationMarker";
import MapLayerControl from "./mapLayerControl/mapLayerControl";
import IncidentMarkerPopup from "./incidentMarkerPopup/incidentMarkerPopup";

function MapViewer({
  cards,
  viewType,
  setCards,
  newMarkerData,
  setCurrentLocAdd,
  toggleIsPopupOpen,
}) {
  const [map, setMap] = useState(null);
  const [newPos, setNewPos] = useState({ latitude: null, longitude: null });
  const [currentFunc, setCurrentFunc] = useState("");
  const [coOrdinates, setCoOrdinates] = useState([]);
  const [isMarkerPanMovement, setIsMarkerPanMovement] = useState(false);
  const [currentMarker, setCurrentMarker] = useState(null);

  useEffect(() => {
    if (map) {
      getCurrentLocation();
    }
  }, [map]);

  useEffect(() => {
    setCurrentFunc(viewType);
  }, [viewType]);

  useEffect(() => {
    if (newMarkerData && map) {
      setCurrentFunc("ADD");
      setNewPos({
        latitude: newMarkerData?.lat,
        longitude: newMarkerData?.lng,
      });
      map.panTo([newMarkerData?.lat, newMarkerData?.lng]);
    }
  }, [newMarkerData]);

  useEffect(() => {
    getCoOrds().then((res) => setCoOrdinates(res));
  }, []);

  const getCurrentLocation = () => {
    updateCardsBasedOnCurrentBounds();
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted" || result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(async (position) => {
              let address = await getAddress(
                position.coords.latitude,
                position.coords.longitude
              );
              updateMapWithLocation(
                address,
                position.coords.latitude,
                position.coords.longitude
              );
            });
          }
        });
    } else {
      alert("Sorry, Maps is not available!");
    }
  };

  const updateCardsBasedOnCurrentBounds = () => {
    let bounds = map.getBounds();
    let payload = {
      envelop: {
        minLatitude: bounds._northEast.lat,
        maxLatitude: bounds._southWest.lat,
        minLongitude: bounds._northEast.lng,
        maxLongitude: bounds._southWest.lng,
      },
    };
    getIncidentByViewBox(payload).then((res) => {
      setCards(res);
    });
  };

  const updateMapWithLocation = (address, latitude, longitude) => {
    setCurrentLocAdd({
      lat: latitude,
      lng: longitude,
      description: address?.replace(", Switzerland", ""),
    });
    const currentLocation = [latitude, longitude];
    setCurrentFunc("CURRENT");
    setNewPos({
      latitude: currentLocation[0],
      longitude: currentLocation[1],
    });
    map.panTo(currentLocation);
  };

  const calculateAddressAndUpdate = (lat, lng) => {
    getAddress(lat, lng).then((address) => {
      setCurrentLocAdd({ lat, lng, description: address });
    });
  };

  return (
    <>
      <div className="layout-container">
        <div className="map-container">
          <MapContainer
            center={[47.391433, 8.045231]}
            zoom={16}
            zoomControl={false}
            scrollWheelZoom={true}
            style={{ height: "100%", width: "100%" }}
            ref={setMap}
            attributionControl={false}
          >
            <MapEventHandler
              setNewPos={setNewPos}
              calculateAddressAndUpdate={calculateAddressAndUpdate}
              toggleIsPopupOpen={toggleIsPopupOpen}
              mapRef={map}
              setCards={setCards}
              isMarkerPanMovement={isMarkerPanMovement}
              setIsMarkerPanMovement={setIsMarkerPanMovement}
              currentMarker={currentMarker}
            />
            <MapLayerControl />
            <IncidentMarkerPopup
              cards={cards}
              mapRef={map}
              setIsMarkerPanMovement={setIsMarkerPanMovement}
              setCurrentMarker={setCurrentMarker}
            />
            {(currentFunc === "ADD" || currentFunc === "CURRENT") && (
              <LocationMarker
                currentFunc={currentFunc}
                setCurrentFunc={setCurrentFunc}
                newPos={newPos}
                setNewPos={setNewPos}
                setCards={setCards}
                calculateAddressAndUpdate={calculateAddressAndUpdate}
              />
            )}

            <ZoomControl position="bottomright" />

            {coOrdinates.map((geoJson, index) => (
              <GeoJSON
                data={geoJson}
                key={"coord" + index}
                style={{ color: "#4361ee" }}
                pathOptions={{
                  fill: index !== 0,
                }}
              ></GeoJSON>
            ))}
          </MapContainer>
        </div>
      </div>
    </>
  );
}

export default MapViewer;
