import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { red } from "@mui/material/colors";
import { convertTimestamp } from "../utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles.module.scss";
import { CardActions } from "@mui/material";

export default function ViewIncident({
  incident,
  handleClose,
  showCloseButton = true,
}) {
  return (
    <div className={styles.viewIncidentWrapper}>
      <Card sx={{ height: "100%", width: "100%" }} className={styles.card}>
        <CardHeader
          action={
            showCloseButton ? (
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ) : null
          }
          title="Meldung"
          className={styles.closeHeader}
          sx={{ color: "#217AB8" }}
        />
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: red[500], textTransform: "capitalize" }}
              aria-label="recipe"
            >
              {incident.name ? incident.name[0] : incident.category}
            </Avatar>
          }
          sx={{ px: 0, fontWeight: "bold" }}
          title={
            <Typography
              className={styles.incidentName}
              variant="h4"
              color="text.primary"
              component="div"
            >
              {incident.name}
            </Typography>
          }
          subheader={convertTimestamp(incident.timestamp, true)}
        />
        <CardMedia
          component="img"
          height="194"
          image={
            incident?.url
              ? incident.url
              : incident?.images.length > 0
              ? URL.createObjectURL(incident.images[0])
              : ""
          }
          alt="Paella dish"
        />
        <CardContent sx={{ px: 0 }}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}
          >
            <Typography variant="h4">Adresse:</Typography>
            <Typography
              className={styles.incidentStatus}
              variant="h5"
              sx={{ color: "#FF6A1C" }}
            >
              {incident.status}
            </Typography>
          </Box>
          <Typography variant="body2" color="text.primary" component="div">
            {incident.category}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {incident.address}
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ whiteSpace: "pre-line" }}
          >
            {incident.description}
          </Typography>
          <Typography paragraph></Typography>
          <Typography variant="h4">Antwort:</Typography>
          <Typography variant="body2" color="text.primary">
            {incident?.response}
          </Typography>
        </CardContent>
        {showCloseButton && (
          <CardActions
            sx={{
              display: "flex",
              borderTop: "1px solid #00000029",
              paddingTop: "1rem",
            }}
          >
            <Button
              variant="outlined"
              sx={{ width: "100%" }}
              onClick={handleClose}
            >
              Abbrechen
            </Button>
          </CardActions>
        )}
      </Card>
    </div>
  );
}
