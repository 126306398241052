import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL;

const NO_ADDRESS = "Keine Adresse";

const convertIncidentResponse = async (incidentArr) => {
  let temp = [];
  for (let incident of incidentArr) {
    let tempObj = {
      name: incident?.name,
      address: incident?.address,
      category: incident?.category,
      status: incident?.status,
      response: incident?.response || "",
      description: incident?.description,
      timestamp: incident?.timestamp,
      position: [incident?.latitude, incident?.longitude],
      id: incident?.id,
      url:
        incident?.imageUrl ||
        "https://static.vecteezy.com/system/resources/previews/000/350/939/original/camera-vector-icon.jpg",
    };
    temp.push(tempObj);
  }
  return temp;
};
export const addIncident = async (payload = {}) => {
  const res = axios.post(`${URL}/api/Location`, payload);
  return res;
};

export const isLocationActive = async (longitude, latitude, address) => {
  const res = axios.post(`${URL}/api/isActive`, null, {
    params: {
      longitude,
      latitude,
      address: address ?? "",
    },
  });
  return res;
};

export const getIncidentByViewBox = async (payload = {}) => {
  const res = axios.post(`${URL}/api/search`, payload, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
  let response = await res;
  return convertIncidentResponse(response?.data?.items);
};

export const getAddress = async (lat, lng) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&location_type=ROOFTOP&key=AIzaSyBHzDNyKh8wXBm3Jr1PR1jkHJHMp6w3JBQ`;
  const res = axios.post(url);
  let response = await res;
  return response?.data?.results[0]?.formatted_address ?? NO_ADDRESS;
};
