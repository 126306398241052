const URL = process.env.REACT_APP_BASE_URL;

export function getCoOrds() {
  return fetch(`${URL}/api/canton`)
    .then((res) => res.json())
    .then((municipalityWrappers) => {
      let promises = [];
      municipalityWrappers.forEach((municipalityWrapper) => {
        promises.push(
          fetchBoundaryData(municipalityWrapper.boundaryUrl).then(
            (geoJsonFeature) => {
              // geoJsonFeature.geometry.type = "MultiPolyLine";
              return geoJsonFeature;
            }
          )
        );
        const municipalityPromises = municipalityWrapper.municipalities.map(
          (municipalityBoundaryInfo) =>
            fetchBoundaryData(municipalityBoundaryInfo.boundaryUrl)
        );
        promises.push(...municipalityPromises);
      });
      return Promise.all(promises);
    });
}

function fetchBoundaryData(boundaryUrl) {
  return fetch(boundaryUrl)
    .then((boundaryData) => boundaryData.json())
    .then((boundaryGeoJson) => boundaryGeoJson.feature);
}
