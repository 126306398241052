import { TileLayer, LayersControl } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

function MapLayerControl() {
  const { BaseLayer } = LayersControl;
  return (
    <LayersControl>
      <BaseLayer checked name="Street View">
        <ReactLeafletGoogleLayer
          checked
          apiKey="AIzaSyBHzDNyKh8wXBm3Jr1PR1jkHJHMp6w3JBQ"
          type={"roadmap"}
          styles={[
            {
              featureType: "poi",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }],
            },
          ]}
        />
      </BaseLayer>
      <BaseLayer name="Satellite View">
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={["mt1", "mt2", "mt3"]}
        />
      </BaseLayer>
    </LayersControl>
  );
}

export default MapLayerControl;
