import styles from "./styles.module.scss";
import Home from "../../assets/Home.svg";
import HomeSelected from "../../assets/home-selected.svg";
import HomeDesktop from "../../assets/home-desktop.svg";
import Road from "../../assets/Road.svg";
import RoadSelected from "../../assets/road-selected.svg";
import RoadDesktop from "../../assets/road-desktop.svg";
import New from "../../assets/New.svg";
import NewSelected from "../../assets/new-selected.svg";
import NewDesktop from "../../assets/new-desktop.svg";


const navItems = [
  {
    name: "Home",
    displayName: "Karte",
    icon: Home,
    selectedIcon: HomeSelected,
    desktopIcon: HomeDesktop
  },
  {
    name: "View",
    displayName: "Alle Meldungen",
    icon: Road,
    selectedIcon: RoadSelected,
    desktopIcon: RoadDesktop
  },
  {
    name: "New",
    displayName: "Neue Meldung",
    icon: New,
    selectedIcon: NewSelected,
    desktopIcon: NewDesktop
  },
];

function NavContainer({ currentPage, moveTo }) {
  return (
    <div className={styles.navItems}>
      {navItems.map((item, index) => (
        <div className={styles.navItemContainer} key={index}>
          <div className={`${styles.navItemIndicator} ${currentPage === item.name && styles.navItemSelectedIndicator}`}></div>
          <div
            onClick={() => moveTo(item.name)}
            className={`${styles.navItems__item} ${
              currentPage === item.name && styles.navItems__item_selected
            }`}
            key={index}
          >
            <img className={styles.mobileIcons}
              src={currentPage === item.name ? item.selectedIcon : item.icon} alt="navigation icon"
            />
            <img className={styles.desktopIcon} src={item.desktopIcon} alt="navigation icon"/>
            <div>{item.displayName}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default NavContainer;
