import { useEffect, useState } from "react";
import "./styles/globals.scss";
import "./App.scss";
import MapViewer from "./components/mapViewer/mapViewer";
import NavContainer from "./components/nav/nav";
// import ViewIncidents from "./components/ViewIncidents";
import IncidentLists from "./components/IncidentLists";
import AddNewIncident from "./components/AddNewIncident/addNewIncident";
import Header from "./components/Header/Header";
import FormStep from "./components/FormStep/formStep";
import AppInfoDialog from "./components/appInfoDialog/appInfoDialog";

function App() {
  const [cards, setCards] = useState([]);
  const [view, setView] = useState(null);
  const [markerId, setMarkerID] = useState(null);
  const [currentNav, setCurrentNav] = useState("Home");
  const [step, setStep] = useState(1);
  const [newMarkerData, setNewMarkerData] = useState(null);
  const [isPopupOpen, toggleIsPopupOpen] = useState(false);

  const navigate = (navigateTo) => {
    setCurrentNav(navigateTo);
  };

  const addNewMarker = (markerData) => {
    setNewMarkerData(markerData);
  };

  const getMarkerDetails = (markerId) => {
    setMarkerID(cards.filter((e) => e.name === markerId));
  };

  const addNewCardData = (newMarker) => {
    setCards((prevData) => {
      return [...prevData, newMarker];
    });
  };

  const dissolveTheIncidentArray = (incidentArr) => {
    setCards((prevData) => [...incidentArr]);
  };

  const handleClose = () => {
    setNewMarkerData(null);
    step > 1 ? setStep(1) : setCurrentNav("Home");
  };

  const handleSetNewMarker = (markerData) => {
    setCards((prevData) => {
      return [...prevData, markerData];
    });
    setStep(1);
    setNewMarkerData(null);
  };

  const [isInfoDialogOpen, toggleInfoDialog] = useState(false);

  const closeInfoDialog = () => {
    toggleInfoDialog(false);
  };

  //display info dialog on startup
  useEffect(() => {
    toggleInfoDialog(true);
  }, []);

  return (
    <div className="main-container">
      {currentNav === "New" && (
        <div className="form-step-container">
          <FormStep currentStep={step} />
        </div>
      )}
      <Header
        currentNav={currentNav}
        markerData={newMarkerData}
        moveTo={navigate}
        addNewMarker={addNewMarker}
      />
      <div className="map-viewer-container">
        <MapViewer
          cards={cards}
          newMarkerData={newMarkerData}
          setCards={dissolveTheIncidentArray}
          addNewCardData={addNewCardData}
          viewType={view}
          setView={setView}
          markerDetails={markerId}
          setCurrentLocAdd={addNewMarker}
          toggleIsPopupOpen={toggleIsPopupOpen}
        />
      </div>
      <div className="nav-container">
        <NavContainer currentPage={currentNav} moveTo={navigate} />
      </div>
      {currentNav === "View" && !isPopupOpen && (
        <IncidentLists incidents={cards} />
      )}
      {currentNav === "New" && (
        <AddNewIncident
          step={step}
          setStep={(step) => setStep(step)}
          markerData={newMarkerData}
          handleSetNewMarker={(form) => handleSetNewMarker(form)}
          handleClose={handleClose}
          addNewMarker={addNewMarker}
        />
      )}
      <AppInfoDialog
        isInfoDialogOpen={isInfoDialogOpen}
        closeInfoDialog={closeInfoDialog}
      ></AppInfoDialog>
    </div>
  );
}

export default App;
