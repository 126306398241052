import { useMapEvents } from "react-leaflet";

import { getIncidentByViewBox } from "../../../services/client";

function MapEventHandler({
  setNewPos,
  calculateAddressAndUpdate,
  toggleIsPopupOpen,
  mapRef,
  setCards,
  isMarkerPanMovement,
  setIsMarkerPanMovement,
  currentMarker,
}) {
  // eslint-disable-next-line no-unused-vars
  const mapEvents = useMapEvents({
    click(e) {
      setNewPos({
        latitude: e?.latlng?.lat,
        longitude: e?.latlng?.lng,
      });
      calculateAddressAndUpdate(e?.latlng?.lat, e?.latlng?.lng);
    },
    popupopen(event) {
      toggleIsPopupOpen(true);

      const popupPixelCoords = mapRef.project(event.popup.getLatLng());
      const popupHeight = event.popup.getElement().clientHeight;
      popupPixelCoords.y -= popupHeight / 2; // move the popup vertical axis location down (distance: half of popup height)
      mapRef.panTo(mapRef.unproject(popupPixelCoords), { animate: true }); // pan to calculated location
    },
    popupclose() {
      toggleIsPopupOpen(false);
    },
    moveend() {
      let bounds = mapRef.getBounds();
      let payload = {
        envelop: {
          minLatitude: bounds._northEast.lat,
          maxLatitude: bounds._southWest.lat,
          minLongitude: bounds._northEast.lng,
          maxLongitude: bounds._southWest.lng,
        },
      };
      getIncidentByViewBox(payload).then((res) => {
        setCards(res);
      });
      if (isMarkerPanMovement) {
        mapRef.closePopup();
        currentMarker.openPopup();
        setIsMarkerPanMovement(false);
      }
    },
  });
  return null;
}

export default MapEventHandler;
