import {
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  CircularProgress,
  Alert,
  FormHelperText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { MAX_COUNT, menuList } from "../utils/utils";
import FormStep from "../FormStep/formStep";
import PlacesAutocomplete from "../autoComplete/autoComplete";
import { addIncident, isLocationActive } from "../../services/client";
import Snackbar from "@mui/material/Snackbar";
import Turnstile, { useTurnstile } from "react-turnstile";

const AddNewIncident = ({
  step,
  setStep,
  handleClose,
  handleSetNewMarker,
  markerData,
  addNewMarker,
}) => {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    category: "",
    address: "",
    description: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [message, setMessage] = useState({
    value: "",
    status: "",
  });
  const [formError, setFormError] = useState({
    isErrorPresent: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isCategoryTouched, setIsCategoryTouched] = useState(false);
  const [isTurnstileVerified, setIsTurnstileVerified] = useState(false);

  const isMobile = useMediaQuery("(max-width:768px)");

  const turnstile = useTurnstile();

  let formElementRef;

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
      if (!limitExceeded) setUploadedFiles(uploaded);
    });
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const approvedExtensions = ["jpeg", "jpg", "png"];

    const areFilesAcceptable = chosenFiles.every((file) => {
      const fileArr = file.name.split(".");
      const extn = fileArr[fileArr.length - 1].toLowerCase();
      return approvedExtensions.indexOf(extn) > -1 ? true : false;
    });

    if (areFilesAcceptable) {
      handleUploadFiles(chosenFiles);
    } else {
      alert(`Only JPEG, JPG & PNG files are accepted`);
    }
  };

  const handleChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    checkFormValidity();
  }, [formElementRef, form]);

  const checkFormValidity = (isUserSubmit = false) => {
    let errorObj = {
      isErrorPresent: false,
      category: "",
    };
    if (formElementRef) {
      if (
        !formElementRef.checkValidity() ||
        (form.email && !isEmailValid(form?.email))
      ) {
        errorObj.isErrorPresent = true;
      }
      if ((isCategoryTouched || isUserSubmit) && !form.category) {
        errorObj.isErrorPresent = true;
        errorObj.category = "Dies ist erforderlich";
      }
    }
    setFormError(errorObj);
    return !errorObj.isErrorPresent;
  };

  const handleSelectChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      category: e.target.value,
    }));
    if (!isCategoryTouched) {
      setIsCategoryTouched(true);
    }
  };

  const handleStep = () => {
    if (step === 1) {
      isLocationActive(markerData.lng, markerData.lat, markerData.description)
        .then((res) => {
          setStep(step + 1);
        })
        .catch((err) => {
          setMessage({
            value: "Für diese Adresse kann keine Meldung erfasst werden.",
            status: "error",
          });
        })
        .finally(() => {
          setTimeout(() => {
            setMessage("");
          }, 3000);
        });
    }
  };

  const fileReader = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        res(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const convertToImageUrl = async (imageArray) => {
    let imageUrls = [];
    let keyArr = Object.values(imageArray);
    for (let key of keyArr) {
      console.log(key);
      let x = await fileReader(key);
      imageUrls.push(x);
    }
    return imageUrls[0];
  };

  const resetForm = () => {
    setIsLoading(false);
    setFormError({
      isErrorPresent: false,
      category: "",
    });
    setTimeout(() => {
      setMessage({
        value: "",
        status: "",
      });
    }, 3000);
  };

  const buildMarketDataFromFormValues = (formData, imageFiles) => {
    return {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      number: formData?.number || "",
      address: markerData.description,
      description: formData.description,
      position: [markerData.lat, markerData.lng],
      timestamp: Date.now(),
      images: imageFiles,
      category: formData?.category,
    };
  };

  const getRequestPayload = async (newMarker, imageFiles) => {
    if (imageFiles.length <= 0)
      newMarker["url"] =
        "https://static.vecteezy.com/system/resources/previews/000/350/939/original/camera-vector-icon.jpg";

    let payload = { ...newMarker };
    payload["longitude"] = newMarker.position[1];
    payload["latitude"] = newMarker.position[0];

    if (newMarker.images.length > 0)
      payload["image"] = await convertToImageUrl(newMarker.images);
    delete payload["position"];
    delete payload["images"];

    return payload;
  };

  const setNewMarkerData = async (formData, imageFiles) => {
    const isFormValid = checkFormValidity(true);
    if (isFormValid) {
      setIsLoading(true);
      const newMarker = buildMarketDataFromFormValues(formData, imageFiles);

      const payload = await getRequestPayload(newMarker, imageFiles);

      addIncident(payload)
        .then((res) => {
          handleSetNewMarker(newMarker);
          setMessage({
            value: "Meldung wurde erfolgreich übermittelt",
            status: "success",
          });
        })
        .catch((err) => {
          setMessage({
            value: "Für diese Adresse kann keine Meldung erfasst werden.",
            status: "error",
          });
        })
        .finally(() => {
          resetForm();
        });
    }
  };

  const isEmailValid = (email) => {
    const EMAIL_REGEX = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

    return EMAIL_REGEX.test(email);
  };

  const onClose = () => {
    setForm({
      firstName: "",
      lastName: "",
      email: "",
      category: "",
      address: "",
      description: "",
    });

    handleClose();
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.form}>
        <div className={styles.formStepContainer}>
          <FormStep currentStep={step} />
        </div>
        <div className={styles.formWrapper}>
          <div className={styles.searchWrapper}>
            {step === 1 && (
              <PlacesAutocomplete
                addNewMarker={addNewMarker}
                markerData={markerData}
              />
            )}
          </div>
          {step === 2 && (
            <form
              className={styles.form}
              noValidate
              ref={(node) => (formElementRef = node)}
            >
              <TextField
                value={form.firstName}
                onChange={(e) => handleChange(e)}
                label="Vorname"
                variant="outlined"
                name="firstName"
              />

              <TextField
                value={form.lastName}
                onChange={(e) => handleChange(e)}
                label="Nachname"
                variant="outlined"
                name="lastName"
              />

              <TextField
                value={form.email}
                onChange={(e) => handleChange(e)}
                label="E-Mail"
                variant="outlined"
                name="email"
                inputProps={{
                  type: "email",
                }}
                error={form.email && !isEmailValid(form.email)}
              />

              <FormControl error={!!formError.category}>
                <InputLabel id="test-select-label">Kategorie*</InputLabel>
                <Select
                  labelId="test-select-label"
                  id="category"
                  value={form.category}
                  variant="outlined"
                  label={"Kategorie"}
                  onChange={handleSelectChange}
                  MenuProps={
                    isMobile
                      ? {
                          classes: {
                            paper: styles.smallSelectDropdown,
                          },
                        }
                      : {}
                  }
                >
                  {menuList.map((el, index) => {
                    return (
                      <MenuItem key={index} value={el}>
                        {el}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{formError.category}</FormHelperText>
              </FormControl>
              <TextField
                value={form.description}
                onChange={(e) => handleChange(e)}
                label="Beschreibung"
                variant="outlined"
                name="description"
                multiline
                rows={3}
                rowsmax={5}
              />
              <TextField
                value={markerData.description}
                onChange={(e) => handleChange(e)}
                label="Adresse"
                variant="outlined"
                name="address"
                disabled
              />
              <>
                <input
                  type="file"
                  id="select-image"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileEvent}
                  disabled={fileLimit}
                  accept="image/*"
                />
                <label
                  htmlFor="select-image"
                  style={{ width: "fit-content" }}
                  className={`label ${!fileLimit ? "" : "disabled"} ${
                    styles.photoUploadBtn
                  }`}
                >
                  <span className="upload-button">Foto hinzufügen</span>
                </label>
                <div className="uploaded-files-list">
                  {uploadedFiles.map((file, index) => (
                    <div key={index}>
                      <img
                        src={URL.createObjectURL(file)}
                        width={60}
                        height={60}
                        alt="uploaded file thumbnails"
                      />
                    </div>
                  ))}
                </div>
                {step === 2 ? (
                  <div>
                    <Turnstile
                      sitekey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY}
                      theme={"light"}
                      onVerify={(token) => {
                        setIsTurnstileVerified(true);
                      }}
                      onError={() => setIsTurnstileVerified(false)}
                      onExpire={() => {
                        setIsTurnstileVerified(false);
                        turnstile.reset();
                      }}
                      fixedSize={true}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            </form>
          )}
        </div>
      </div>
      <div className={styles.progressContainer}>
        {isLoading && <CircularProgress />}
      </div>

      <div className={styles.buttonContainer}>
        <Button variant="outlined" onClick={onClose} className={styles.button}>
          Abbrechen
        </Button>
        <Button
          variant="contained"
          onClick={
            step === 1
              ? handleStep
              : () => setNewMarkerData(form, uploadedFiles)
          }
          className={styles.button}
          disabled={
            (step === 1 && !markerData) ||
            (step === 2 && (formError.isErrorPresent || !isTurnstileVerified))
          }
        >
          {step === 1 ? "Weiter" : "Senden"}
        </Button>
      </div>
      <Snackbar
        open={message.value ? true : false}
        autoHideDuration={3000}
        sx={{ zIndex: "1004", bottom: 120 }}
        className={styles.snackbar}
      >
        <Alert severity={message.status} sx={{ width: "100%" }}>
          {message.value}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddNewIncident;
